<template>
    <div class="rounded-lg sm:h-140 md:h-144 lg:h-144 bg-gradient-to-b from-gradiantRV1 to-gradiantRV2 ">
        <div class="sm:h-40 md:h-64 lg:h-72 w-full bg-center bg-cover" :style="`background-image: url('`+bgRegisterImg+`')`">
            <div class="flex justify-end mr-4 pt-2"><strong class=" cursor-pointer" @click="close()">X</strong></div>
        </div>
        <div class="w-full">
            
            <div class="flex items-center justify-center">
                <img class="sm:mt-3 md:mt-8 h-10 md:h-16" :src="imgLogotype" alt="">
            </div>

            <div class="w-full flex justify-center text-center items-center sm:mt-3 md:mt-5">
                <p class="sm:text-sm md:text-base lg:text-lg text-white">Disfruta de tu lugar favorito y recomienda para que otros lo conozcan.</p>
            </div>

            <div :class="classBodyRegister">

                <div class="grid sm:grid grid-cols-1 md:grid-cols-2 justify-items-center">
                    <div class="sm:mt-0 md:mt-2 text-white">
                        <input class="shadow appearance-none border border-solid border-white bg-inputAuth rounded-lg sm:w-64 md:w-72 lg:w-72 xl:w-72 py-2 px-3 text-white leading-tight  focus:shadow-outline placeholder-white"  type="text" placeholder="Nombre" name="nameUser">
                    </div>
                    <div class="mt-2">
                        <input class="shadow appearance-none border border-solid border-white bg-inputAuth  rounded-lg sm:w-64 md:w-72 lg:w-72 xl:w-72 py-2 px-3 text-white leading-tight  focus:shadow-outline placeholder-white"  type="text" placeholder="Primer Apellido" name="apellidoUser">
                    </div>
                    <div class="mt-2">
                        <input class="shadow appearance-none border border-solid border-white bg-inputAuth  rounded-lg sm:w-64 md:w-72 lg:w-72 xl:w-72 py-2 px-3 text-white leading-tight  focus:shadow-outline placeholder-white"  type="text" placeholder="Teléfono" name="telefonoUser">
                    </div>
                    <div class="mt-2">
                        <input class="shadow appearance-none border border-solid border-white bg-inputAuth  rounded-lg sm:w-64 md:w-72 lg:w-72 xl:w-72 py-2 px-3 text-white leading-tight  focus:shadow-outline placeholder-white"  type="email" placeholder="Correo" name="emailUser">
                    </div>
                    <div class="mt-2">
                        <input class="shadow appearance-none border border-solid border-white bg-inputAuth  rounded-lg sm:w-64 md:w-72 lg:w-72 xl:w-72 py-2 px-3 text-white leading-tight  focus:shadow-outline placeholder-white"  type="password" placeholder="Contraseña" name="passwordUser">
                    </div>
                    <div class="mt-2"> 
                        <input class="shadow appearance-none border border-solid border-white bg-inputAuth  rounded-lg sm:w-64 md:w-72 lg:w-72 xl:w-72 py-2 px-3 text-white leading-tight  focus:shadow-outline placeholder-white"  type="password" placeholder="Confirmar Contraseña" name="validatePasswordUser">
                    </div>
                </div>

                <div class="text-center ml-15 mr-15 sm:mt-4 md:mt-8">
                    <p class="text-white text-xs">Al continuar, aceptas las <span class="font-semibold cursor-pointer underline">Condiciones del servicio</span> y confirmas que has leído nuestra <span class="font-semibold cursor-pointer underline">Política de privacidad.</span></p>
                </div>

            </div>

            <div :class="classBodyTags">

    

<div class="">

                    <v-chip-group
                    v-model="amenities"
                    column
                    multiple
                    class=""
                >
                    <v-chip
                    v-for="(selection, i) in tags"
                    :key="i"
                    filter
                    outlined
                    color="white"
                    >
                    {{ selection.name }}
                    </v-chip>

                </v-chip-group>
</div>
            </div>

            <div class="flex justify-center items-center sm:pt-2 md:pt-4">
                <button @click="goToTags" class="mx-auto sm:w-64 md:w-72 lg:w-80 xl:w-96  bg-white hover:bg-fucsia-lighter text-fucsia-dark hover:text-white font-bold py-2 px-4 rounded-lg focus:outline-none">
                    Siguiente
                </button>
            </div>

        </div>
    </div>

</template>

<script>

import toastMixin from "@/mixins/vue/toastmixin";
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import AuthModel from "../../../models/auth/auth.model";
import tagsService from '../../../services/catalogs/tags.service';

export default {
    name : "RegisterLogin",
    mixins: [toastMixin,validationMixin],
    props:{
        timeToExpired : {
            type : Number,
            default : 0
        }
    },
    data: function() {
        return {
            bgRegisterImg:      require('@/assets/animation.png'),
            imgLogotype:        require('@/assets/logotype/logoAuth.png'),
            auth:               new AuthModel(),
            isloading:          false,
            loading:            false,
            WindowWidth:        '',
            classBodyRegister:  'animate-fade-in-up',
            classBodyTags:      'hidden',
            isRegisterTrue:     true,
            tags:               [],
            tagsselected:       []
        }
    },
    validations: {
        auth: {
            email: {
                required
            },
            password: {
                required
            }
        }
    },
    created(){
        this.winWidth()
    },
    mounted() {
     window.onresize = () => {
        this.WindowWidth    = document.documentElement.clientWidth;
     };
     this.init()
    },
    methods: {
        init: async function() {
            await this.getTags();
        },
        
        winWidth: function () {
            this.WindowWidth    = screen.width;
        },
        async close(){
            this.$emit('success',0);
        },
        async submit() {

        },
        getTags: async function() {
            let response = await tagsService.getAll()
            if(response.success) {
                this.tags = response.data;
            }
        },
        goToTags: function(){
            if(this.isRegisterTrue){
                this.classBodyRegister = 'animate-fade-in-up'
                this.isRegisterTrue = false
                this.classBodyRegister = 'hidden'
                this.classBodyTags  = 'animate-fade-in-right flex justify-center'
            }
            else{
                this.classBodyRegister = 'animate-fade-in-right'
                this.isRegisterTrue = true
                 this.classBodyTags  = 'hidden  flex justify-center'
            }
        }
    }
}
</script>

<style>

</style>