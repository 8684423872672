<template>
   
<div class="grid  sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-12 h-full">

    <div  v-show="WindowWidth >= 1026" class="bg-gradient-to-bl from-gradiantAuthV1 to-gradiantAuthV2 col-start-1 col-end-2 md:col-start-1 md:col-end-4">
        
        <div class="flex justify-center h-32 mt-24">
              <div class="absolute 4  h-24 w-96 flex items-center justify-center">
                <img class="sm:h-12 md:h-14 lg:h-16 xl:h-20" :src="imgLogotype" alt="">
              </div>
        </div>

        <div class="mt-5">
            <div class="text-left sm:ml-8 md:ml-10 lg:ml-12 xl:ml-15  sm:mr-8 md:mr-10 lg:mr-12 xl:mr-15">
                <p class="text-xl lg:text-3xl text-right text-white font-sans">
                    A unos clicks de distancia de <strong> encontrar todo lo que buscas</strong>
                </p>
            </div>
        </div>

        <div v-if="WindowWidth <= 1026" class="mt-5">
               <lottie-animation
                    :path="'lottie/loginganimation.json'" 
                    :width="250"
                    :height="250"     
                    :speed="1.5"     
                />
        </div>
        <div  v-if="WindowWidth > 1026"  class="mt-5">
               <lottie-animation
                    :path="'lottie/loginganimation.json'" 
                    :width="450"
                    :height="450"     
                    :speed="1.5"     
                />
        </div>

    </div>

    <div class="sm:col-start-1 sm:col-end-2 md:col-start-1 md:col-end-2 lg:col-start-4 lg:col-end-13 ">
        <div class="bg-gradient-to-r from-gradiantAuth1 to-gradiantAuth2 h-full w-full">
            <div class="h-full w-full bg-center bg-cover" :style="`background-image: url('`+bgImageUrl+`')`">
            <v-loading :is_loading="isloading" />
                
                <div class="flex items-center justify-center h-full w-full">

                    <div class="">
                        <!-- LOGOTIPO REBYU DESKTOP -->
                        <div v-show="WindowWidth >= 1026" class="flex justify-center w-full">
                            <div class="">
                                <a href="/" target=""> <!-- target _blank opens in new tab-->
                                    <img class="-mt-10 mb-20 h-16" :src="imgLogotype" alt="">
                                </a>
                                
                            </div>
                        </div>

                        <!-- BIENVENIDO -->
                        <div class="mb-5 text-center">
                            <h1 class="text-white font-bold sm:text-2xl md:text-4xl lg:text-5xl xl:text-6xl mb-2" style="letter-spacing: 5px; font-family: 'Montserrat', sans-serif;">BIENVENIDO</h1>
                            <small class="text-gray-200 text-base font-semibold tracking-wider">BUSCA - DISFRUTA - RESEÑA</small>
                        </div>

                        <!-- ANIMATION MOBILE -->
                         <div v-if="WindowWidth <= 1026" class="mt-0">
                            <lottie-animation
                                    :path="'lottie/loginganimation.json'" 
                                    :width="250"
                                    :height="250"     
                                    :speed="1.5"     
                                />
                        </div>
                        <!-- INPUTS -->
                        <div class="rounded-md bg-white bg-opacity-20 sm:h-80 md:h-84 lg:h-84 xl:h-96 sm:w-80 md:w-80 lg:w-96 xl:w-128  button-with-gradient-border shadow-md" style="backdrop-filter: blur(10px);">
                            <div class="flex justify-center">
                                <div class="sm:mt-5 md:mt-12">
                                    <div class="mb-5">
                                        <label class="text-white " style="letter-spacing: 1px;" for="emailUser">Email</label>
                                        <div class="">
                                            <input v-model="$v.auth.email.$model" class="shadow appearance-none border border-solid bg-gray-500 border-white rounded-lg sm:w-full md:w-72 lg:w-80 xl:w-96 py-2 px-3 text-white leading-tight  focus:shadow-outline"  type="email" placeholder="Correo Electrónico" name="emailUser">
                                        </div>
                                        <small class="text-error-dark" v-show="$v.auth.email.$anyDirty && !$v.auth.email.required">* El correo es requerido</small>
                                    </div>

                                    <div class="mt-3">
                                        <label class="text-white " for="emailUser" style="letter-spacing: 1px;">Contraseña</label>
                                        <div class="mt-1">
                                            <input v-on:keyup.enter="submit" id="pwd" v-model="$v.auth.password.$model" class="shadow appearance-none border border-solid bg-gray-500 border-white rounded-lg sm:w-full md:w-72 lg:w-80 xl:w-96 py-2 px-3 mb-2 text-white leading-tight  focus:shadow-outline" type="password" placeholder="Contraseña">
                                        </div>
                                        <small class="text-error-dark" v-show="$v.auth.password.$anyDirty && !$v.auth.password.required">* La contraseña es requerida</small>
                                        <rb-exception class="text-error-dark-200"  v-if="authException && !authException.success " >* {{ authException.payload ? authException.payload : '' }}</rb-exception>
                                    </div>

                                    <div class="mt-5">
                                        <button @click="submit" class="mx-auto sm:w-64 md:w-72 lg:w-80 xl:w-96 border border-solid border-white bg-fucsia-lighter hover:bg-fucsia-dark text-white font-bold py-2 px-4 rounded-lg focus:outline-none">
                                            Iniciar Sesión
                                        </button>
                                        <div class="mt-2">
                                            <div class="w-full">
                                                <div class="flex justify-between items-center mb-4">
                                                    <div class="flex items-center">
                                                        <input v-model="$v.auth.active_session.$model" id="checkbox-2" aria-describedby="checkbox-2" type="checkbox"  class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                                                        <label for="checkbox-2" class="ml-2 text-xs font-medium text-white dark:text-gray-300">Mantener sesión activa</label>
                                                    </div>
                                                    <h6 class="text-white text-xs ml-4" @click="resset=true"><strong class="cursor-pointer">Olvide mi contraseña</strong></h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="flex justify-center">
                                            <h6 class="text-gray-300 text-xs">¿Aún no eres miembro?  <label @click="mostrar('register')"><strong class="text-fucsia-lighter text-sm cursor-pointer">Registrate Gratis</strong></label></h6> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- LOGOTIPO REBYU MOBILE -->
                        <div v-show="WindowWidth < 1026" class="flex justify-center w-full">
                            <div class="">
                                <img class="mt-10 mb-5 h-10" :src="imgLogotype" alt="">
                            </div>
                        </div>

                    </div>

                </div>

            </div>
        </div>
    </div>

<!-- Modal Register -->
        <v-dialog
          v-if="showLogin"
          v-model="showLogin"
          persistent
         max-width="800px"
        >
          <v-register
            v-if="showLogin"
            v-on:close="showLogin = false"
            @success="fnsuccess($event)"
          />
        </v-dialog>

       
</div>

</template>

<script>

import {mapActions, mapGetters} from "vuex";
import AuthModel from "../../models/auth/auth.model";
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import ToastMixin from '../../mixins/vue/toastmixin';
import { RoleService } from '../../services/HSuite/Context/hsuite-core-context';
import  modalRegister from './components/registerModel.vue';
import { MODULES, TYPES } from '../../store/common/HMVuexCommon'

export default {
    name: 'login',
    mixins : [validationMixin, ToastMixin],
    components: { 'v-register':modalRegister },
    data: function() {
        return {
            WindowHeight:   '',
            WindowWidth:    '',
            login:          '',
            auth:           new AuthModel(),
            isloading:      false,
            loading:        false,
            animacion:      true,
            resset:         false,
            showLogin:      false,
            roles:          [],
            bgImageUrl:     require('@/assets/login_bg2.png'),
            imgLogotype:    require('@/assets/logotype/logoAuth.png')
        }
    },
    validations: {
        auth: {
            email: {
                required
            },
            password: {
                required
            },
            active_session: {
                required
            }
        }
    },
    methods : {
        winWidth: function () {
            this.WindowWidth    = screen.width;
            this.WindowHeight   = screen.height;
        },
        ...mapActions(MODULES.AUTH, [
            TYPES.HM_AUTH_SIGIN
        ]),

        mostrar(iddiv){
            this.showLogin = true
            document.getElementById(iddiv).scrollIntoView();
        },
        register(id_role, required_licence){
            if(!required_licence) {
                this.$router.push({name: 'register', query: {x: id_role}});
            }else {
                this.$router.push({name: 'partners', query: {x: id_role}});
            }
        },

        async submit() {
            this.$v.$touch();
            if(!this.$v.$anyError) {
                this.isloading = true;
                const response =  await this.sigIn(this.auth);
                this.isloading = false;
                if(response.success && this.user) {
                        this.$router.push({name: 'IndexHome'});
                }
                
            }else {
                this.warning('', 'Complete los datos requeridos');
            }
        },
        fnsuccess: async function(event) {
            if (event > 0) {
                this.showLogin = false;
            } else {
                this.showLogin = false;
            }
        },
        getRoles: async function() {
            this.loading = true;
            let response = await RoleService.getRolesBySystem();
            this.loading = false;
            if(response.success) {
                this.roles = response.data;
            }
        }

    },
    created(){
        this.winWidth()
    },
    mounted() {
     window.onresize = () => {
        this.WindowWidth    = document.documentElement.clientWidth;
        this.WindowHeight   = document.documentElement.clientHeight;
     };
    },
    computed: {
        ...mapGetters( MODULES.AUTH, {
           user: TYPES.HM_AUTH_GET_USER,
           authException: TYPES.HM_AUTH_EXCEPTION,
      }),
    },
    watch: {
        animacion: async function() {
            if(!this.animacion) {
                this.roles = [];
                await this.getRoles();
            }
        }
    }
    
}
</script>

<style scoped>
    .a-link-color {
        color:#6366f1 !important
    }
.button-with-gradient-border {
background-image: radial-gradient(circle at 100% 100%, transparent 4px, #eee8e8 4px, #eee8e8 5px, transparent 5px), linear-gradient(to right, #eee8e8, #ffcccc), radial-gradient(circle at 0% 100%, transparent 4px, #ffcccc 4px, #ffcccc 5px, transparent 5px), linear-gradient(to bottom, #ffcccc, #fe0638), radial-gradient(circle at 0% 0%, transparent 4px, #fe0638 4px, #fe0638 5px, transparent 5px), linear-gradient(to left, #fe0638, #f6f5ee), radial-gradient(circle at 100% 0%, transparent 4px, #f6f5ee 4px, #f6f5ee 5px, transparent 5px), linear-gradient(to top, #f6f5ee, #eee8e8);
background-size: 5px 5px, calc(100% - 10px) 1px, 5px 5px, 1px calc(100% - 10px);
background-position: top left,top center,top right,center right, bottom right,bottom center,bottom left,center left;
background-repeat: no-repeat;
}
</style>